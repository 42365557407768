import React, { createRef, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DatePicker from "sassy-datepicker";
import moment from "moment-timezone";
import { TimeSelection } from "../components";
import { Button } from "@mui/material";
import * as Images from "../themes/images";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #001571",
  boxShadow: 24,
  p: 2,
  maxWidth: "900px",
  maxHeight: "90%",
  borderRadius: "6px",
};

class BookingTimeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedTime: null,
      selectedDate: null,
      onLoadDate: null,
      locationAvailability: null,
      step: 1,
      removeClass: false,
    };
    this.handleStep = this.handleStep.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.selectTime = this.selectTime.bind(this);
    this.mobileDateRef = createRef();
    this.desktopDateRef = createRef();
  }

  handleOpen() {
    this.setState({
      selectedDate: moment().format("MMM Do"),
    });
    this.props.toggleDateModal(true);
  }

  handleStep(val) {
    this.setState({
      step: this.state.step + val,
    });
  }
  handleClose() {
    this.props.toggleDateModal(false);
    this.setState({
      locationAvailability: null,
      step: 1,
    });
    this.props.resetAvailability(); // this function is not working
  }
  onDateChange(date) {
    let selectedDate = moment(date).format("YYYY-MM-DD");
    var closedDates = this.props.location.closedDates;
    console.log("this.props.location: ", this.props.location);
    if (closedDates !== null && closedDates[selectedDate]) {
      this.props.selectedDate(new Date());
      alert(
        "Sorry, we are closed on this date and cannot accept your selected date"
      );
      this.setState({
        removeClass: true,
      });
      return;
    }
    this.setState({
      removeClass: false,
    });
    this.setState({
      selectedTime: null,
      selectedDate: moment(date).format("MMM Do"),
    });
    if (this.props.updateBooking) {
      this.props.updateSelectedDate(selectedDate);
    } else {
      this.props.selectedDate(selectedDate);
    }
  }

  selectTime(cap, time) {
    // let dateTime = this.props.selectedBookingTime ||  new Date()
    // dateTime = dateTime.split(" ");
    let date = this.state.selectedDate
      ? this.state.selectedDate
      : moment().format("MMM Do");
    if (cap > 0) {
      this.setState({
        selectedTime: time,
      });
      let t = time;
      let ampm = t.split(" - ")[0].includes("AM") ? "AM" : "PM";
      let selectedDateTimeUTC = moment
        .utc(date + " " + t.split(" - ")[0], "MMM Do hA")
        .format("YYYY-MM-DD HH:mm:ss");
      this.props.handleSelectedTime(
        date + " " + time,
        selectedDateTimeUTC,
        ampm,
        time
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.openBookingModal !== this.props.openBookingModal) {
      if (!this.props.updateBooking) {
        this.setState({
          open: this.props.openBookingModal,
          selectedTime: this.props.selectedTime,
        });
        let dateTime = this.props.selectedBookingTime;
        let date = false;
        if (dateTime) {
          dateTime = dateTime.split(" ");
          date = moment(dateTime[0] + " " + dateTime[1], "MMM Do").format(
            "YYYY-MM-DD"
          );
          let selectedDate = moment(date).format("YYYY-MM-DD");
          this.props.selectedDate(selectedDate);
        } else {
          let selectedDate = moment().format("YYYY-MM-DD");
          this.props.selectedDate(selectedDate);
        }
      } else {
        this.setState({
          open: this.props.openBookingModal,
          selectedTime: this.props.selectedTime,
        });

        let dateTime = this.props.selectedBookingTime;
        let date = false;
        if (dateTime) {
          dateTime = dateTime.split(" ");
          date = moment(dateTime[0] + " " + dateTime[1], "MMM Do").format(
            "YYYY-MM-DD"
          );
          let selectedDate = moment(date).format("YYYY-MM-DD");
          this.props.updateSelectedDate(selectedDate);
        }
      }
    }

    if (this.state.removeClass) {
      if (this.desktopDateRef.current) {
        const children = this.desktopDateRef.current.children;

        for (let i = 0; i < children[1].children.length; i++) {
          children[1].children[i].classList.remove("sdp--date-btn__selected");
        }
      }
      if (this.mobileDateRef.current) {
        const children = this.mobileDateRef.current.children;

        for (let i = 0; i < children[1].children.length; i++) {
          children[1].children[i].classList.remove("sdp--date-btn__selected");
        }
      }
    }

    if (prevProps.locationAvailability !== this.props.locationAvailability) {
      this.setState({
        locationAvailability: this.props.locationAvailability,
      });
    }
  }
  render() {
    let dateTime = this.props.selectedBookingTime;
    let date = false;
    if (dateTime) {
      dateTime = dateTime.split(" ");
      date = moment(dateTime[0] + " " + dateTime[1], "MMM Do").format(
        "YYYY-MM-DD"
      );
      date = new Date(date);
    }
    let hideCount = 0;
    return (
      <div>
        <Modal
          open={this.state.open}
          // onClose={() =>  this.handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popupModalDateTime"
        >
          {window.innerWidth > 820 ? (
            <Box sx={style}>
              <h2 className="modalTitle">Arrival Time</h2>
              <p className="modalDesc">
                Note: Bookings within the hour aren't accepted.
              </p>
              <div className="calendarTimeSection">
                <div className="twoColumn">
                  <h4>Select your date</h4>
                  <div className="calendarArea">
                    <DatePicker
                      ref={this.desktopDateRef}
                      minDate={new Date(moment().subtract(1, "days"))}
                      className="bookingCalendar"
                      onChange={this.onDateChange}
                      selected={date ? date : new Date()}
                    />
                  </div>
                </div>
                <div className="twoColumn">
                  <h4>Select your arrival time</h4>
                  <div className="timeArea">
                    {this.state.locationAvailability === null ? (
                      <p>Select a date to retrieve times...</p>
                    ) : (
                      this.state.locationAvailability.map((key, i) => {
                        let now = moment();
                        let lastBookableTime = moment(
                          key.timeStart,
                          "HH:mm"
                        ).add(15, "minutes");
                        let startBookableTime = moment(key.timeStart, "HH:mm");
                        let checkTimeBefore = false;

                        if (this.state.selectedDate == null) {
                          checkTimeBefore =
                            moment().format("YYYY-MM-DD") ==
                            moment().format("YYYY-MM-DD");
                        } else {
                          checkTimeBefore =
                            moment(this.state.selectedDate, "MMM Do").format(
                              "YYYY-MM-DD"
                            ) == moment().format("YYYY-MM-DD");
                        }

                        if (checkTimeBefore) {
                          if (
                            lastBookableTime.isBefore(now) ||
                            now.isBetween(startBookableTime, lastBookableTime)
                          ) {
                            hideCount++;
                            if (
                              hideCount ==
                              this.state.locationAvailability.length
                            ) {
                              return (
                                <p>
                                  Sorry, No times availabe for the date
                                  selected.
                                </p>
                              );
                            }
                            return null;
                          }
                        }

                        if (this.state.selectedDate !== null) {
                          let selectedDateTime = moment(
                            this.state.selectedDate,
                            "MMM Do"
                          ).format("YYYY-MM-DD");

                          var earlyCloseDate =
                            this.props.location.earlyCloseTime;

                          var timeAllowed =
                            earlyCloseDate && earlyCloseDate[selectedDateTime]
                              ? earlyCloseDate[selectedDateTime]
                              : 0;
                          let [hoursStr, minutesStr] = key.timeStart.split(":");
                          let hours = parseInt(hoursStr);
                          let minutes = parseInt(minutesStr);
                          let decimalHours = hours + minutes / 60;
                          if (decimalHours < timeAllowed) {
                            hideCount++;
                            return null;
                          }

                          if (
                            hideCount == this.state.locationAvailability.length
                          ) {
                            return (
                              <p>
                                Sorry, No times availabe for the date selected.
                              </p>
                            );
                          }
                        }

                        return (
                          <TimeSelection
                            objectKey={key.timeStart}
                            time={key.capacity}
                            selectedTime={this.state.selectedTime}
                            select={this.selectTime}
                            discount={this.props.discount}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="modalFooter">
                <Button
                  className="selectButton"
                  onClick={() => {
                    this.props.updateSelectedService();
                    this.props.toggleDateModal(false);
                  }}
                >
                  Select
                </Button>
              </div>
            </Box>
          ) : (
            <Box sx={style}>
              <div
                className={`dateTimeStep dateTimeStep${this.state.step} `}
              ></div>
              <div className="closeButton">
                <img
                  src={Images.closeIcon}
                  alt=""
                  onClick={() => this.handleClose()}
                />
              </div>
              <h2 className="modalTitle">Booking date & time</h2>
              <p className="modalDesc">
                Note: Bookings withing the hour aren't accepted.
              </p>
              <div className="calendarTimeSection">
                <div className="twoColumn">
                  {this.state.step === 1 ? (
                    <>
                      <h4>Select your date</h4>
                      <div className="calendarArea">
                        <DatePicker
                          ref={this.mobileDateRef}
                          minDate={new Date(moment().subtract(1, "days"))}
                          className="bookingCalendar"
                          onChange={this.onDateChange}
                          selected={date ? date : new Date()}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="selectedDate"
                      onClick={() => {
                        this.handleStep(-1);
                      }}
                    >
                      <img src={Images.blueLeftArrow} alt="" />
                      <p>
                        {this.state.selectedDate
                          ? `${this.state.selectedDate} ${
                              this.state.selectedTime
                                ? this.state.selectedTime
                                : dateTime
                                ? `${dateTime[2]} - ${dateTime[4]}`
                                : ""
                            }`
                          : this.props.selectedBookingTime}
                      </p>
                    </div>
                  )}
                </div>
                <div className="twoColumn">
                  {this.state.step === 2 && (
                    <>
                      <h4 className="selectTimeTitle">
                        Select your arrival time
                      </h4>
                      <div className="timeArea">
                        {this.state.locationAvailability === null ? (
                          <p>Select a date to retrieve times...</p>
                        ) : (
                          this.state.locationAvailability.map((key, i) => {
                            let now = moment();
                            let lastBookableTime = moment(
                              key.timeStart,
                              "HH:mm"
                            ).add(15, "minutes");
                            let startBookableTime = moment(
                              key.timeStart,
                              "HH:mm"
                            );
                            let checkTimeBefore = false;

                            if (this.state.selectedDate == null) {
                              checkTimeBefore =
                                moment().format("YYYY-MM-DD") ==
                                moment().format("YYYY-MM-DD");
                            } else {
                              checkTimeBefore =
                                moment(
                                  this.state.selectedDate,
                                  "MMM Do"
                                ).format("YYYY-MM-DD") ==
                                moment().format("YYYY-MM-DD");
                            }

                            if (checkTimeBefore) {
                              if (lastBookableTime.isBefore(now)) {
                                hideCount++;
                                return null;
                              }
                            }

                            let selectedDateTime = moment(
                              this.state.selectedDate,
                              "MMM Do"
                            ).format("YYYY-MM-DD");

                            var earlyCloseDate =
                              this.props.location.earlyCloseTime;

                            var timeAllowed =
                              earlyCloseDate && earlyCloseDate[selectedDateTime]
                                ? earlyCloseDate[selectedDateTime]
                                : 0;
                            let [hoursStr, minutesStr] =
                              key.timeStart.split(":");
                            let hours = parseInt(hoursStr);
                            let minutes = parseInt(minutesStr);
                            let decimalHours = hours + minutes / 60;
                            if (decimalHours < timeAllowed) {
                              hideCount++;
                              return null;
                            }

                            return (
                              <TimeSelection
                                objectKey={key.timeStart}
                                time={key.capacity}
                                selectedTime={this.state.selectedTime}
                                select={this.selectTime}
                                discount={this.props.discount}
                              />
                            );
                          })
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modalFooter">
                {this.state.step === 1 ? (
                  <Button
                    className="selectButtonMobile"
                    onClick={() => {
                      this.handleStep(1);
                    }}
                  >
                    Select a time{" "}
                    <span>
                      <img src={Images.whiteRightArrow} alt="" />
                    </span>
                  </Button>
                ) : (
                  <Button
                    className="selectButtonMobile"
                    onClick={() => {
                      this.props.updateSelectedService();
                      this.props.toggleDateModal(false);
                      this.setState({
                        step: 1,
                      });
                    }}
                  >
                    Confirm Date & Time
                  </Button>
                )}
              </div>
            </Box>
          )}
        </Modal>
      </div>
    );
  }
}

export default BookingTimeModal;
