import React from "react";
import * as Images from "../themes/images";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DatePicker from 'sassy-datepicker';
import moment from "moment";
import { TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #001571',
    boxShadow: 24,
    p: window.innerWidth < 820 ? 2 : 5,
    maxWidth: window.innerWidth < 820 ? "93%" : "40%",
    maxHeight: "80%",
    borderRadius: "6px",
};

class SubscriptionLearnModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleClose(){
        this.setState({
            open: false,
        });
    }

    handleOpen(){
        this.setState({
            open: true,
        });
    }

    handleSelect(){
        this.props.selectSubscription();
        this.handleClose();
    }
    
    render() {
        return (
            <div>
                <Button className="extrasExampleButton" onClick={() => this.handleOpen()}>
                    <span>{"Learn more +"}</span>
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={() =>  this.handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="popupModal subsriptionModal"
                >
                <Box sx={style}>
                    <div className="extraExampleHeadings">
                        <h5 className="extraName">{this.props.sub.name}</h5>
                    </div>
                    <img src={this.props.sub.image} className="subImage" alt="subsription" />
                    <p className="modalLightText">{this.props.sub.description}</p>
                    <h5 className="extraPrice ">${this.props.sub.price}/{this.props.sub.cadence.substring(0,1)}</h5>
                    <div className="extraExampleButtonSection">
                        <button className={this.props.isSelected ? "blueBorderButton" : "lightBlueButton"} onClick={() => this.handleSelect()}> {this.props.isSelected ? "Unselect" : "Select"} </button>
                    </div>
                </Box>
                </Modal>
            </div> 
        )
    }
}

export default SubscriptionLearnModal;