import axios from "axios";

export const api = async function (endpoint, type, data) {
  const origin = "https://api.conciergecarwash.co.nz/v1/";
  // const origin = "http://localhost:8089/v1/";
  let request = await axios({
    method: type,
    url: origin + endpoint,
    data: data,
  });
  return request;
};

export const googleEvent = async function (ReactGA, GTM, event, value) {
  if (event == "booking_confirmed") {
    const booking = await api(`booking/${value.bookingNumber}`, "GET", null);
    // console.log("get booking: ", booking);

    if (booking.status == 200) {
      let data = booking.data.booking;
      let items = [];
      for (let i = 0; i < data.cart.sessionData.selectedServiceId.length; i++) {
        const element = data.cart.sessionData.selectedServiceId[i];
        let obj = {
          item_name: element.name,
          item_id: element.id,
          quantity: element.quantity,
        };
        items.push(obj);
      }
      GTM.dataLayer({
        dataLayer: {
          event: event,
          eventProps: {
            value: value,
          },
        },
      });
      GTM.dataLayer({
        dataLayer: {
          event: "ecommerce",
          ecommerce: {
            purchase: {
              currency: "NZD",
              value: data.cart.sessionData.estimatedTotal,
              discount: data.cart.sessionData.discount,
              coupon: data.cart.sessionData.couponCode,
              reward: data.cart.sessionData.rewardCode,
              offer: data.cart.sessionData.offerCode,
              transaction_id: value.bookingNumber,
              items: items,
            },
          },
          user_email: data.posResponse.BookingEmail,
        },
      });
      ReactGA.event(event, value);
    }
  } else {
    ReactGA.event(event, value);
  }
};
