import React from "react";
import { CardOption, BookingPricingCard } from "../../components/";
class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: "",
      selectedParentService: this.props.selectedParentService
        ? this.props.selectedParentService
        : "",
      serviceOptions: [
        {
          name: "Car Wash",
          isSelected: true,
        },
        {
          name: "Wash & Detail",
          isSelected: false,
        },
      ],
    };
    this.changeServiceSelected = this.changeServiceSelected.bind(this);
    this.selectServiceType = this.selectServiceType.bind(this);
  }

  changeServiceSelected(value) {
    this.setState({
      selectedParentService: value,
    });
    this.setState((prevState) => ({
      serviceOptions: prevState.serviceOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
  }

  selectServiceType(value, name, price, time) {
    const vehicleType = this.props.selectedVehicleType
      .split(" ")[0]
      .toLowerCase();
    const pTemplate = this.props.selectedLocation.pricingTemplate;
    const servicePrice = this.props.discount
      ? price[pTemplate][vehicleType]
      : price.discount
      ? price[pTemplate][vehicleType] - price.discount
      : price[pTemplate][vehicleType];
    this.setState({
      selectedService: value,
    });
    this.props.handleSelectServiceType(
      name,
      this.state.selectedParentService,
      value,
      servicePrice,
      time
    );
  }

  componentDidMount() {
    //console.log("service props: ", this.props)
    if (this.props.selectedParentService !== "") {
      this.setState((prevState) => ({
        serviceOptions: prevState.serviceOptions.map((obj) =>
          obj.name === this.props.selectedParentService
            ? Object.assign(obj, { isSelected: true })
            : Object.assign(obj, { isSelected: false })
        ),
      }));
    }

    if (typeof this.props.selectedServiceId == "object") {
      if (this.props.selectedServiceId.length > 0) {
        this.setState({
          selectedService: this.props.selectedServiceId[0].id,
        });
      }
    } else {
      if (this.props.selectedServiceId !== "") {
        this.setState({
          selectedService: this.props.selectedServiceId,
        });
      }
    }
  }

  render() {
    let parentThis = this;
    const pricingOrder = [
      "Express Wash",
      "Economy Wash",
      "Premium Wash",
      "Super Wash",
      "Hand Polish",
      "Interior Detail",
      "Full Detail",
      "Paint Protection",
    ];
    const sortByObject = pricingOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let pricingOptions = [];
    if (this.props.selectedLocation.pricing) {
      pricingOptions = this.props.selectedLocation.pricing.sort(
        (a, b) =>
          sortByObject[a.service[0].name] - sortByObject[b.service[0].name]
      );
    }

    return (
      <div className="serviceStep">
        <h3>Select your service type</h3>
        <div className="serviceTypeOptions">
          {this.props.selectedLocation !== "" &&
            this.state.serviceOptions.map(function (item, i) {
              return (
                <CardOption
                  key={i}
                  item={item}
                  select={(item) => parentThis.changeServiceSelected(item)}
                />
              );
            })}
        </div>
        <div className="bookingPriceGrid">
          {pricingOptions.map(function (item, i) {
            var selectedService = "";
            var selectedType = parentThis.props.selectedVehicleType;
            for (
              let st = 0;
              st < parentThis.state.serviceOptions.length;
              st++
            ) {
              const element = parentThis.state.serviceOptions[st];
              if (element.isSelected) {
                selectedService = element.name;
              }
            }
            if (item.serviceType[0].name === selectedService) {
              if (parentThis.props.timeAmPm == "AM") {
                if (
                  item.timeOfDay[0].startTimeHour === 8 &&
                  item.timeOfDay[0].endTimeHour === 13
                ) {
                  return (
                    <BookingPricingCard
                      key={i}
                      item={item}
                      selectedType={selectedType}
                      discount={parentThis.props.discount}
                      selectServiceType={() =>
                        parentThis.selectServiceType(
                          item._id,
                          item.service[0].name,
                          item.pricing,
                          item.service[0].duration
                        )
                      }
                      selectedService={parentThis.state.selectedService}
                      bundleSelected={parentThis.props.selectedBundle}
                      pricingTemplate={
                        parentThis.props.selectedLocation.pricingTemplate
                      }
                    />
                  );
                }
              } else {
                if (
                  parentThis.props.dataRange.split(" - ")[1] == "2PM" ||
                  parentThis.props.dataRange.split(" - ")[1] == "1PM"
                ) {
                  if (
                    item.timeOfDay[0].startTimeHour == 8 &&
                    item.timeOfDay[0].endTimeHour == 13
                  ) {
                    return (
                      <BookingPricingCard
                        key={i}
                        item={item}
                        selectedType={selectedType}
                        selectServiceType={() =>
                          parentThis.selectServiceType(
                            item._id,
                            item.service[0].name,
                            item.pricing,
                            item.service[0].duration
                          )
                        }
                        selectedService={parentThis.state.selectedService}
                        bundleSelected={parentThis.props.selectedBundle}
                        pricingTemplate={
                          parentThis.props.selectedLocation.pricingTemplate
                        }
                        discount={parentThis.props.discount}
                      />
                    );
                  }
                } else {
                  if (
                    item.timeOfDay[0].startTimeHour == 14 &&
                    item.timeOfDay[0].endTimeHour == 18
                  ) {
                    return (
                      <BookingPricingCard
                        key={i + 10}
                        item={item}
                        selectedType={selectedType}
                        selectServiceType={() =>
                          parentThis.selectServiceType(
                            item._id,
                            item.service[0].name,
                            item.pricing,
                            item.service[0].duration
                          )
                        }
                        selectedService={parentThis.state.selectedService}
                        bundleSelected={parentThis.props.selectedBundle}
                        pricingTemplate={
                          parentThis.props.selectedLocation.pricingTemplate
                        }
                        discount={parentThis.props.discount}
                      />
                    );
                  }
                }
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default Service;
