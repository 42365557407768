import React from "react";
import './booking.css';
import * as Images from "../../themes/images";
import { instanceOf } from 'prop-types';
import HeaderMobile from "../../components/headerMobile"
import { withCookies, Cookies } from 'react-cookie';
class CanceledSubscription extends React.Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const { cookies } = props;
        this.state = {
            subscriptions: [],
            giftCardDetails: false
        }
        this.goBackCCW = this.goBackCCW.bind(this);
    }

    componentDidMount(){
        document.title = 'Canceled Subscription Purchase - Concierge Car Wash';
        const { cookies } = this.props;
        let selectedSubs = cookies.get('selectedSubs');
        let giftCardDetails = cookies.get('giftCardDetails');
        selectedSubs = selectedSubs.split(',');
        if(selectedSubs == 0){
            this.setState({
                giftCardDetails: giftCardDetails
            })
        } else {
            this.setState({
                subscriptions: selectedSubs,
            })
        }

    }

    goBackCCW(){
        window.location.href = "https://conciergecarwash.co.nz/"
    }

    render(){
        return(
            <div className="stripeCanceledPage">
                {window.innerWidth < 767 && <HeaderMobile/>}
                <div className="bookingBanner">
                    <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
                </div>
                <div className="successContent cancelledText">
                    <h1>You canceled your purchase {this.state.giftCardDetails ? "for the following gift card" : "for the chosen subscription/s"}</h1>  
                    {
                        this.state.subscriptions.length > 0 && (
                            <div className="listedSubs">
                                <ul>
                                    {
                                        this.state.subscriptions.map(function(sub){
                                            return (
                                                <li className={sub}>{sub}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    }
                    {
                        this.state.giftCardDetails && (
                            <div className="listedSubs">
                                <p>Gift Card for {this.state.giftCardDetails.firstName + " " + this.state.giftCardDetails.lastName+ " | Amount: " + this.state.giftCardDetails.amount}</p>
                            </div>
                        )
                    }
                    
                    <button className="lightBlueButton" onClick={() => this.goBackCCW()}> Continue </button>
                </div>
            </div>
        )
    }
}

export default withCookies(CanceledSubscription);