import React from "react";
import { api, googleEvent } from "../../api";
import "../Booking/booking.css";
import * as Images from "../../themes/images";
import { instanceOf } from "prop-types";
import HeaderMobile from "../../components/headerMobile";
import { withCookies, Cookies } from "react-cookie";
import Loading from "../../components/Loading";
import ReactPixel from "react-facebook-pixel";

class SubscriptionSuccess extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      successSub: false,
      error: false,
      errorMessage: "",
      loading: true,
      subscriptionActive: false,
      subscriptions: [],
      subLoading: false,
    };
    this.updateUserSubscription = this.updateUserSubscription.bind(this);
  }

  // async createSubCard(data) {
  //   let res = await api("booking/subscriptioncard/create", "POST", data);
  //   console.log("data: ", data);
  //   console.log("res: ", res);
  //   if (res.status == 200) {
  //     if (res.data.success) {
  //       var subs = [];
  //       for (let sc = 0; sc < res.data.cardIds.length; sc++) {
  //         const element = res.data.cardIds[sc];
  //         subs.push(element);
  //         googleEvent(
  //           this.props.ReactGA,
  //           this.props.GTM,
  //           "subscription_card_created",
  //           { subscriptionId: element }
  //         );
  //       }

  //       if (res.data.cardIds.length > 0) {
  //         this.setState({
  //           subscriptionActive: true,
  //           subscriptions: subs,
  //           subLoading: false,
  //         });
  //       }
  //     }
  //   }
  // }

  async updateUserSubscription(session_id, customer, rego, sub, location) {
    let data = {
      idCustomer: customer,
      session_id: session_id,
      carRego: rego,
      sub: sub,
      location: location,
    };

    let res = await api("subscription/create", "POST", data);
    if (res.status == 200) {
      let resp = res.data;
      if (resp.success) {
        console.log(resp.subscriptions);
        this.setState({
          subscriptionActive: true,
          subscriptions: resp.subscriptions,
          subLoading: false,
          loading: false,
        });
        googleEvent(
          this.props.ReactGA,
          this.props.GTM,
          "subscription_added_customer",
          { subscriptionId: resp.data.stripeId }
        );
      } else {
        alert(resp.message);
      }
    }
  }

  setupBeforeUnloadListener = () => {
    const parentState = this;
    window.addEventListener("beforeunload", (ev) => {
      if (parentState.state.loading) {
        ev.preventDefault();
        return (ev.returnValue =
          "Are you sure you want to close? Your booking has not been processed yet and you may forfeit your $2 booking fee.");
      } else {
        return;
      }
    });
  };

  componentDidMount() {
    document.title = "Booking Success - Concierge Car Wash";
    const { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    let session_id = queryParams.get("session_id");
    let idCustomer = queryParams.get("customer");
    let carRego = queryParams.get("rego");
    let sub = queryParams.get("sub");
    let location = queryParams.get("location");
    // ReactPixel.pageView();

    this.updateUserSubscription(session_id, idCustomer, carRego, sub, location);
    this.setupBeforeUnloadListener();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="bookingSuccessPage">
          {window.innerWidth < 767 && <HeaderMobile />}
          <div className="bookingBanner">
            <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
          </div>
          <div className="watingMessage">
            <h1>
              We are processing your booking, please do not leave this page
              until you recieve your booking reference number.
            </h1>
          </div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="bookingSuccessPage">
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="bookingBanner">
          <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
        </div>
        {this.state.error ? (
          <div className="failContent">
            <h1>{this.state.errorMessage}</h1>
            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={"https://conciergecarwash.com.au"}
              >
                {" "}
                Continue{" "}
              </a>
            </div>
          </div>
        ) : (
          <div className="successContent">
            <img
              src={Images.blueTick}
              className="successTick"
              alt="success tick"
            />
            <h1>You successfully subscribed</h1>

            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={"https://conciergecarwash.com.au"}
              >
                {" "}
                Continue{" "}
              </a>
            </div>
            {this.state.subLoading && (
              <div style={{ marginTop: 50 }}>
                <h4>Your Subscriptions are being created, please wait...</h4>
                <Loading />
              </div>
            )}
            {this.state.subscriptionActive && (
              <>
                <h2 className="subsctriptionHeading">
                  Your purchased subscriptions:{" "}
                </h2>
                <div className="listedSubs">
                  <ul>
                    {this.state.subscriptions.map(function (sub) {
                      return <li className={sub.name}>{sub.name}</li>;
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
        <footer class="successFoooter">
          <img src="https://hustleoutbound.s3.ap-southeast-2.amazonaws.com/637b47ec89db6c2ec519888a_ccwCleanCar.jpeg " />
        </footer>
      </div>
    );
  }
}

export default withCookies(SubscriptionSuccess);
