import React from "react";
import { greyClockIcon } from "../themes/images";
class BookingPricingCard extends React.Component {
  render() {
    let type = this.props.selectedType;
    type = type.toLowerCase().split(" / ")[0].trim();
    let includeText = "";
    let isSelected = false;

    if (this.props.bundleSelected !== null) {
      if (this.props.selectedService) {
        if (typeof this.props.selectedService == "object") {
          if (
            this.props.selectedService[0].id == this.props.item.service[0]._id
          ) {
            isSelected = true;
          }
        } else {
          if (this.props.selectedService == this.props.item.service[0]._id) {
            isSelected = true;
          }
        }
      }
    } else {
      if (this.props.selectedService == this.props.item._id) {
        isSelected = true;
      }
    }
    let salePrice = this.props.discount
      ? false
      : this.props.item.pricing["discount"]
      ? Number(
          typeof this.props.item.pricing[this.props.pricingTemplate][type] ==
            "object"
            ? this.props.item.pricing[this.props.pricingTemplate][type].value
            : this.props.item.pricing[this.props.pricingTemplate][type]
        ) - Number(this.props.item.pricing["discount"])
      : false;

    // console.log("sale price: ", salePrice);
    switch (this.props.item.service[0].name.toLowerCase().trim()) {
      case "express wash":
        includeText = "What's included";
        break;
      case "economy wash":
        includeText = "Express wash plus";
        break;
      case "premium wash":
        includeText = "Economy wash plus";
        break;
      case "super wash":
        includeText = "Premium wash plus";
        break;
      case "hand polish":
        includeText = "Premium wash plus";
        break;
      case "full detail":
        includeText = "Premium wash plus";
        break;
      case "paint protection":
        includeText = "Premium wash plus";
        break;
      default:
        includeText = "";
        break;
    }

    let pTemplate = this.props.pricingTemplate;
    let pricing = this.props.item.pricing;
    let price = pricing[pTemplate];

    price[type] = price[type].value ? price[type].value : price[type];

    console.log("this.props.discount: ", this.props.discount);
    console.log("salePrice: ", salePrice);

    if (price["unavailable"]) {
      return null;
    } else {
      return (
        <div
          className={
            "pricingCard" +
            (this.props.item["popular"] ? " mostPopular" : "") +
            (isSelected ? " selectedService" : "")
          }
        >
          <h2 className="pricingCardHeading">
            {this.props.item.service[0].name}
          </h2>
          {this.props.item.pricing[this.props.pricingTemplate] !== undefined ? (
            salePrice ? (
              this.props.item.pricing[this.props.pricingTemplate][type] ==
              "POA" ? (
                <h2 className="pricingCardPOA">
                  {this.props.item.pricing[this.props.pricingTemplate][type]}
                </h2>
              ) : (
                <h2 className={"pricingCardText"}>
                  <span className="originalPrice">
                    ${this.props.item.pricing[this.props.pricingTemplate][type]}
                  </span>{" "}
                  ${salePrice}
                </h2>
              )
            ) : this.props.item.pricing[this.props.pricingTemplate][type] ==
              "POA" ? (
              <h2 className="pricingCardPOA">
                {this.props.item.pricing[this.props.pricingTemplate][type]}
              </h2>
            ) : (
              <h2 className={"pricingCardText"}>
                ${this.props.item.pricing[this.props.pricingTemplate][type]}
              </h2>
            )
          ) : null}
          <div
            className={
              isSelected
                ? "bookNowButton"
                : "blueBorderButton bookingPriceSelect"
            }
            onClick={() => this.props.selectServiceType(this.props.item._id)}
          >
            {isSelected ? "Selected" : "Choose"}
          </div>
          <div className="serviceDurationSection">
            <img className="durationIcon" src={greyClockIcon} alt="clock" />
            <p className="serviceDuration">
              {" From " + this.props.item.service[0].duration + "min"}
            </p>
          </div>
          {/* <p className="smallDesc">{this.props.item.service[0].description}</p> */}
          <p className="includedText lightBlueText">{includeText}</p>
          <ul className="includedItemsList">
            {this.props.item.service[0].includedOptions.map(function (
              listItem,
              i
            ) {
              return (
                <li key={i} className="includedItem">
                  {listItem}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
}

export default BookingPricingCard;
