import React from "react";
import * as Images from "../themes/images";
import moment from "moment";

class TimeSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let heading = "";
    let isTimeSelected = false;
    let discountApply = false;

    let timeText =
      moment(this.props.objectKey, "HH:mm").format("h:mmA") +
      " - " +
      moment(this.props.objectKey, "HH:mm").add(15, "minutes").format("h:mmA");

    if (moment(Number(this.props.objectKey), "HH:mm").format("HH") >= 14) {
      discountApply = this.props.discount ? false : true;
    }

    if (this.props.selectedTime == timeText) {
      if (this.props.time <= 0) {
        isTimeSelected = false;
      } else {
        isTimeSelected = true;
      }
    }

    if (this.props.time !== null) {
      return (
        <div
          key={this.props.objectKey}
          className={
            "timeOptionBox" + (isTimeSelected == true ? " selectedTime" : "")
          }
        >
          <div className="timeOptions">
            {heading && <h5>{heading}</h5>}
            <div
              className={"timeOption1"}
              onClick={() => this.props.select(this.props.time, timeText)}
            >
              <div className={isTimeSelected ? "check isActive" : "check"}>
                <img src={Images.blueTick} alt="Checked" />
              </div>
              {this.props.time <= 0 ? (
                <p className="noBookingAvailableText">
                  {timeText} <br />
                  <span className="optionNote unavailable">Drive-in Only</span>
                </p>
              ) : (
                <p>
                  {timeText} <br />
                  <span className="optionNote">Booking Available</span>
                  {discountApply && (
                    <p className="afterNoonDiscount">$5 discount</p>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TimeSelection;
