import React, { useState } from 'react'
import * as Images from "../themes/images";
function Header() {

    const websiteURL = "https://conciergecarwash.co.nz/";

    const goToHome = () => {
        window.location.href = websiteURL;
    }
    
    
    return (
        <div className='headerMobile'>
            <div className='headerMobileBottom'>
                <img src={Images.ccwLogoLight} alt="" onClick={() => goToHome()} />
            </div>
        </div>
    )
}

export default Header