import React, { useState } from 'react'
import * as Images from "../themes/images";
import DiscountProgressBar from './discountProgressBar';

function DiscountBubble(props) {
    console.log("total: ", props.estimatedTotal);
    let gotDiscount = props.estimatedTotal >= 200;
    console.log("got total > 200: ", gotDiscount);
    return (
        <div className={`discountBubble ${gotDiscount ? 'gotDiscount' : ''}`}>
            {
                gotDiscount ? 
                <div className="bubbleRow">
                    <img src={Images.whiteTick} className="discountTick" />
                    <p className='bubbleTextWhite'>You have earnt a 10% discount</p>
                </div> :
                <p className='bubbleText'>Order over $200 to receive 10% off</p>
            }
            {
                gotDiscount == false && ( 
                    <DiscountProgressBar type={"mobile"}  amount={`${props.estimatedTotal/props.cartThreshold * 100}%`} />
                )
            }
        </div>
    )
}

export default DiscountBubble