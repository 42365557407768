import React from "react";
import { api, googleEvent } from "../../../api";
import {
  Details,
  Service,
  Extras,
  Confirmation,
  Step,
  CustomProgressBar,
  BundleModal,
} from "../../../components/";
import "./update.css";
import * as Images from "../../../themes/images";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Loading from "../../../components/Loading";
import { withAuth0 } from "@auth0/auth0-react";
import { BookingTimeModal } from "../../../components/";
import moment from "moment";
import Button from "@mui/material/Button";
import HeaderMobile from "../../../components/headerMobile";
import DocumentMeta from "react-document-meta";

class UpdateBooking extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      isLoading: true,
      booking: null,
      openBookingModal: false,
      locationAvailability: null,
      selectedDate: null,
      selectedTime: null,
      checkModalOpen: false,
      selectedLocation: null
    };
    this.baseState = this.state;

    this.getBooking = this.getBooking.bind(this);
    this.toggleDateModal = this.toggleDateModal.bind(this);
    this.updateSelectedDate = this.updateSelectedDate.bind(this);
    this.handleUpdateDateTime = this.handleUpdateDateTime.bind(this);
    this.handleSelectedTime = this.handleSelectedTime.bind(this);
    this.checkModal = this.checkModal.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
    this.updateBookingDate = this.updateBookingDate.bind(this);
  }

  async getBooking(id) {
    try {
      const booking = await api(`booking/${id}`, "GET", null);
      if (booking.status == 200) {
        if (booking.data.success) {
          this.setState({
            booking: booking.data.booking,
            locationAvailability: booking.data.locationAvailability,
            selectedLocation: booking.data.booking.cart.sessionData.selectedLocation,
            isLoading: false,
          });
        } else {
          alert("Could not find booking with booking reference provided.");
          this.setState({
            booking: null,
            isLoading: false,
          });
        }
      } else {
        alert("Could not find booking with booking reference provided.");
        this.setState({
          booking: null,
          isLoading: false,
        });
      }
    } catch (error) {
      alert("Could not find booking with booking reference provided.");
      this.setState({
        booking: null,
        isLoading: false,
      });
    }
  }

  toggleDateModal(val) {
    this.setState({
      openBookingModal: val,
    });
  }

  async updateSelectedDate(val) {
    let data = {
      date: val,
      selectedLocation: this.state.booking.idLocation._id,
    };

    let res = await api("location/availability", "POST", data);
    if (res.status === 200) {
      if (res.data.success) {
        let locationAvailability = res.data.locationAvailability;
        this.setState({
          locationAvailability: locationAvailability,
        });
        // return res.data.locationAvailability;
      }
    } else {
      console.log(res);
    }
  }

  async handleUpdateDateTime() {
    // console.log("updateSelectedDate: ", this.state);
  }

  async handleSelectedTime(val, utc, am, range) {
    this.setState({
      selectedDate: val,
      selectedTime: range,
    });
  }

  async cancelBooking() {
    try {
      let res = await api(
        `booking/cancel/${this.state.booking.posBookingKey}`,
        "PUT",
        null
      );
      if (res.status === 200) {
        if (res.data.success) {
          alert("Booking was canceled.");
          window.location.replace("/manage-booking");
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      alert("Could not find booking with booking reference provided.");
      this.setState({
        booking: null,
        isLoading: false,
      });
    }
  }

  async updateBookingDate() {
    if (this.state.selectedDate == null) {
      alert("Please update date and time before saving.");
      return;
    }
    let res = await api(
      `booking/update/${this.state.booking.bookingKey}`,
      "PUT",
      {
        selectedBookingTime: this.state.selectedDate,
      }
    );
    if (res.status === 200) {
      if (res.data.success) {
        alert("Booking Date & Time updated.");
        window.location.reload();
      }
    } else {
      console.log(res);
      if (res.status == 404) {
        alert(res.data.response);
      }
    }
  }

  checkModal(isOpen) {
    if (isOpen) {
      return (
        <div className="checkModalContainer">
          <div className="checkModalCard">
            <h3>Are you sure you want to cancel your booking?</h3>
            <div className="buttonSection">
              <Button
                onClick={() =>
                  this.setState({
                    checkModalOpen: !this.state.checkModalOpen,
                  })
                }
                className="blueBorderButton modalButton"
              >
                Keep Booking
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    checkModalOpen: !this.state.checkModalOpen,
                    isLoading: true,
                  });
                  this.cancelBooking();
                }}
                className="lightBlueButton modalButton"
              >
                Cancel Booking
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  componentDidMount() {
    document.title = "Update Booking - Concierge Car Wash";
    let { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const hasBookingId = queryParams.get("bookingid");

    if (hasBookingId) {
      this.getBooking(hasBookingId);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    var selectedDate = null;
    var Time = null;
    if (this.state.selectedDate) {
      selectedDate = this.state.selectedDate;
      Time = this.state.selectedTime;
    } else {
      if (this.state.booking) {
        let date = moment(this.state.booking.date).format("MMM Do");
        let firstTime =
          this.state.booking.time.hour + this.state.booking.time.ampm;
        let secondTime =
          Number(this.state.booking.time.hour) + 1 >= 12
            ? Number(this.state.booking.time.hour) + 1 + "PM"
            : Number(this.state.booking.time.hour) + 1 + "AM";
        let range = firstTime + " - " + secondTime;
        Time = range;
        selectedDate = `${date} ${range}`;
      }
    }
    const meta = {
      title: "Amend your booking with Concierge Car Wash",
      description:
        "Update, upgrade or reschedule your Concierge Car Wash booking using our new and fast booking system. Australia's premium 100% Hand-Only car wash destination.",
    };

    return (
      <DocumentMeta {...meta}>
        <div className="bookingPage manageBooking">
          {window.innerWidth < 767 && <HeaderMobile />}
          <div className="bookingBanner" style={{ height: 50 }}></div>
          <div className="bookingHeader manageBookingHeader">
            <div className="logoSection">
              <a href="/">
                <img src={Images.ccwLogoLight} className="ccwLogo" alt="Logo" />
              </a>
            </div>
            <div className="titleSection manageBookingTitle">
              <h1>Manage Your Booking</h1>
            </div>
            <div className="closeButtonSection"></div>
          </div>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="updateBookingContainer">
                <div className="flexCol headings">
                  <div
                    className={
                      this.state.booking && window.innerWidth < 767
                        ? "updateCell updateCellLeft"
                        : "updateCell"
                    }
                  >
                    <h5>Booking Reference</h5>
                  </div>
                  {this.state.booking && window.innerWidth > 767 && (
                    <div>
                      <div className="updateCell">
                        <h5>Date and Time Range</h5>
                      </div>
                      <div className="updateCell">
                        <h5>Location</h5>
                      </div>
                      <div className="updateCell">
                        <h5>Service</h5>
                      </div>
                      <div className="updateCell">
                        <Button
                          onClick={() => this.updateBookingDate()}
                          className="lightBlueButton"
                        >
                          Save Date & Time
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flexCol inputs">
                  <div
                    className={
                      this.state.booking && window.innerWidth < 767
                        ? "updateCell updateCellLeft"
                        : "updateCell"
                    }
                  >
                    <input
                      name="bookingRef"
                      id="bookingRef"
                      defaultValue={
                        this.state.booking
                          ? this.state.booking.posBookingKey
                          : ""
                      }
                      onBlur={(val) => this.getBooking(val.target.value)}
                    />
                  </div>
                  {this.state.booking && (
                    <div className="updateBookingTimeWrapper">
                      {window.innerWidth < 767 && (
                        <div className="updateCellLeft">
                          <h5>Date and Time Range</h5>
                        </div>
                      )}
                      <div className="updateCell">
                        <Button
                          className="bookingTimeButton"
                          onClick={() =>
                            this.toggleDateModal(!this.state.openBookingModal)
                          }
                        >
                          <span>
                            {selectedDate
                              ? selectedDate
                              : "Select your booking date and time"}
                          </span>
                          <img
                            src={Images.calendarIco}
                            className="calendarIco"
                            alt="calendar"
                          />
                        </Button>
                      </div>
                      {window.innerWidth < 767 && (
                        <div className="updateCellLeft">
                          <h5>Location</h5>
                        </div>
                      )}
                      <div
                        className={
                          window.innerWidth < 767
                            ? "updateCell bookingTextMobile"
                            : "updateCell"
                        }
                      >
                        <p className="bookingText">
                          {this.state.booking &&
                            this.state.booking.idLocation.seoName}
                        </p>
                      </div>
                      {window.innerWidth < 767 && (
                        <div className="updateCellLeft">
                          <h5>Service</h5>
                        </div>
                      )}
                      <div
                        className={
                          window.innerWidth < 767
                            ? "updateCell bookingTextMobile"
                            : "updateCell"
                        }
                      >
                        <p className="bookingText">
                          {this.state.booking &&
                            this.state.booking.selectedServices.map(function (
                              item
                            ) {
                              return item.name;
                            })}
                        </p>
                      </div>
                      {window.innerWidth > 820 && (
                        <div className="updateCell">
                          <Button
                            onClick={() =>
                              this.setState({
                                checkModalOpen: !this.state.checkModalOpen,
                              })
                            }
                            className="blueBorderButton"
                          >
                            Cancel Booking
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {this.state.booking && window.innerWidth < 767 && (
                  <div className="fixedButton">
                    <Button
                      onClick={() =>
                        this.setState({
                          checkModalOpen: !this.state.checkModalOpen,
                        })
                      }
                      className="blueBorderButton"
                    >
                      Cancel Booking
                    </Button>
                    <Button
                      onClick={() => this.updateBookingDate()}
                      className="lightBlueButton"
                    >
                      Save Date & Time
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {this.state.booking == null && (
            <div className="flexRow manageBookingButtons">
              <div className="searchButtonSection">
                <Button
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  className="blueBorderButton"
                >
                  Go Back
                </Button>
              </div>
              <div className="searchButtonSection">
                <Button onClick={null} className="lightBlueButton">
                  Search
                </Button>
              </div>
            </div>
          )}
          <BookingTimeModal
            locationAvailability={this.state.locationAvailability}
            openBookingModal={this.state.openBookingModal}
            selectedBookingTime={selectedDate}
            toggleDateModal={this.toggleDateModal}
            selectedDate={selectedDate}
            selectedTime={Time}
            updateSelectedDate={this.updateSelectedDate}
            handleSelectedTime={this.handleSelectedTime}
            updateBooking={true}
            updateSelectedService={this.handleUpdateDateTime}
            location={this.state.selectedLocation}
          />
          {this.checkModal(this.state.checkModalOpen)}
        </div>
      </DocumentMeta>
    );
  }
}

export default withCookies(UpdateBooking);
